<template>
  <div>
    <input
        ref="input"
        accept="image/*"
        name="image"
        type="file"
        @change="setImage"
    />

    <div class="content">
      <section class="cropper-area">
        <div class="img-cropper">

            <vue-cropper
                :auto-crop-area="1"
                ref="cropper"
                :src="getImageSrc"
                preview=".preview"
                :aspect-ratio="aspectRatio"
                v-show="getImageSrc"
                @cropend="$emit('update')"
                @zoom="$emit('update')"
            />

            <div class="img-placeholder" v-show="!getImageSrc">
              <div class="no-pics"/>
            </div>

        </div>
        <div class="actions">
          <a href="#" role="button" @click.prevent="zoom(0.2)">
            <font-awesome-icon icon="search-plus"/>
          </a>
          <a href="#" role="button" @click.prevent="zoom(-0.2)">
            <font-awesome-icon icon="search-minus"/>
          </a>
          <a href="#" role="button" @click.prevent="move(-10, 0)">
            <font-awesome-icon icon="arrow-left"/>
          </a>
          <a href="#" role="button" @click.prevent="move(10, 0)">
            <font-awesome-icon icon="arrow-right"/>
          </a>
          <a href="#" role="button" @click.prevent="move(0, -10)">
            <font-awesome-icon icon="arrow-up"/>
          </a>
          <a href="#" role="button" @click.prevent="move(0, 10)">
            <font-awesome-icon icon="arrow-down"/>
          </a>
          <a href="#" role="button" @click.prevent="rotate(-45)">
            <font-awesome-icon icon="undo"/>
          </a>
          <a href="#" role="button" @click.prevent="rotate(45)">
            <font-awesome-icon flip="horizontal" icon="undo"/>
          </a>
          <a href="#" role="button" @click.prevent="flipX">
            <font-awesome-icon icon="arrows-alt-h"/>
          </a>
          <a href="#" role="button" @click.prevent="flipY">
            <font-awesome-icon icon="arrows-alt-v"/>
          </a>
          <a href="#" role="button" @click.prevent="reset">
            <font-awesome-icon icon="sync" title="Reset"/>
          </a>
          <a href="#" v-if="showUploadButton" role="button" @click.prevent="showFileChooser">
            <font-awesome-icon icon="upload" :title="$t('generic.lang_uploadImage')"/>
          </a>
          <a href="#" v-if="showDeleteButton" role="button" class="bg-red" @click.prevent="removeImage">
            <font-awesome-icon icon="trash" :title="$t('generic.lang_deleteImage')"/>
          </a>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsAltV,
  faArrowUp,
  faCrop,
  faSearchMinus,
  faSearchPlus,
  faSync,
  faTrash,
  faUndo,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'


library.add(
    faSearchPlus,
    faSearchMinus,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faUndo,
    faArrowsAltV,
    faArrowsAltH,
    faCrop,
    faSync,
    faUpload,
    faTrash,
);

export default {
  components: {
    VueCropper,
    'font-awesome-icon': FontAwesomeIcon
  },

  props: {
    value: String,

    showDeleteButton: {
      type: Boolean,
      default: true
    },
    showUploadButton: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      type: Number,
      default: 4 / 3
    }
  },

  data() {
    return {
      imgSrc: this.value,
      cropImg: '',
      data: null,
    };
  },
  watch:{
    value(val){
      this.imgSrc=val;
    }
  },
  computed:{
    getImageSrc(){
      return this.imgSrc
    }
  },
  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      if(!this.imgSrc) {
        this.$emit("input", null);
        return;
      }
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL("image/png", 0.7);

      this.$emit("input", this.cropImg);
    },
    getCroppedCanvas() {
      if (this.$refs.cropper.getCroppedCanvas() !== null) {
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL("image/png", 0.7);

        this.$emit("input", this.cropImg);
      }
      return this.$refs.cropper.getCroppedCanvas();
    },
    flipX() {
        let scale = this.$refs.cropper.getData().scaleX;
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleX(scale);
    },
    flipY() {
        let scale = this.$refs.cropper.getData().scaleY;
        scale = scale ? -scale : -1;
        this.$refs.cropper.scaleY(scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
      this.$emit('update');
    },
    reset() {
      this.$refs.cropper.reset();
      this.$emit('reset');
    },
    clear() {
      this.$refs.cropper.destroy();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
      this.$emit('update');
    },
    setCropBoxData() {
      if (!this.data) return;
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;
      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert(this.$t('generic.lang_pleaseSelectAnImageFile'));
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);

        };
        reader.readAsDataURL(file);
        this.$emit('update');
      } else {
        alert(this.$t('generic.lang_sorryFileReaderApiNotSupported'));
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
      this.$emit('update');
    },
    removeImage(){
      this.imgSrc='';
      this.$emit('update');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="file"] {
  display: none;
}

.content {
  text-align: center;
}

.cropper-area {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062CC;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.image-area {
  min-width: 300px;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.img-placeholder {
  width: 100%;
  height: 260px;
  background-image: url('../../assets/images/blank-bg.png');
  background-repeat: repeat;
}

.no-pics {
  margin: 0 auto;
  height: 250px;
  width: 250px;
  background-image: url('../../assets/images/no-pics.png');
}

.cropped-image img {
  max-width: 100%;
}

.bg-red{
  background-color: #f44336!important;
}
</style>
<style>
.cropper-bg {
  background-repeat: repeat !important;
}
</style>
